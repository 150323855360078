
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import { FilteredDatabase } from '../../../worker/fd/FilteredDatabase';
import ForecastModalMonth from './components/ForecastModalMonth.vue';

@Component({
	components: { ForecastModalMonth },
})
export default class ForecastModalLoadFixedExpenses extends BaseForecastModalPage {
	public manualFixedExpensesGrowth = '0';
	public showLoading = false;

	public async loadExpensesAverage() {
		const doc = this.doc;
		const date = doc.fixedExpenses.dateRange;
		const ref = FilteredDatabase.ref('transactions').dateRange(date);
		const head = await ref.includes('accType', ['EP']).get();
		const sum = await head.getSum('amount');
		const [d0, d1] = date.map((d) => moment(d));
		const diff = d1.diff(d0, 'month') + 1;
		return sum / diff;
	}
	public async copyExpenses() {
		const doc = this.doc;
		const mmt = moment(doc.fixedExpenses.dateRange[0]);
		const syncResult: number[] = [];
		for (let i = 0; i < 12; i++) {
			const ref = FilteredDatabase.ref('transactions').month(
				mmt.year(),
				mmt.month(),
			);
			const head = await ref.includes('accType', ['EP']).get();
			const sum = await head.getSum('amount');
			syncResult.push(sum);
			mmt.add(1, 'month');
		}
		return syncResult;
	}
	public get computedFixedExpenses() {
		const doc = this.doc;
		const growth = doc.fixedExpenses.growth;
		const sync = doc.fixedExpenses.syncResult;
		const months = [...sync];
		for (let i = 1; i < 12; i++) {
			months[i] = sync[i] * (1 + growth / 100);
		}
		return months;
	}
	public get limitedComputedExpenses() {
		return this.computedFixedExpenses.slice(0, this.period);
	}
	@Watch('manualFixedExpensesGrowth')
	public onFixedExpensesGrowthChanged(newVal: string) {
		const number = Number(newVal);
		this.doc.fixedExpenses.growth = number;
	}

	public async fromPreviousPage(passData: any) {
		const doc = this.doc;
		if (passData && passData.resync) {
			this.showLoading = true;
			if (doc.fixedExpenses.type === 'average') {
				doc.fixedExpenses.syncResult = await new Array(12).fill(
					this.loadExpensesAverage(),
				);
			} else {
				doc.fixedExpenses.syncResult = await this.copyExpenses();
			}
			doc.fixedExpenses.syncAt = Date.now();
			this.showLoading = false;
		}
	}

	public mounted() {
		const doc = this.doc;

		const fe = doc.fixedExpenses;
		const result = fe.growth;
		this.manualFixedExpensesGrowth = result === result ? result.toString() : '0';
	}
	public onNext() {
		const doc = this.doc;
		const fe = doc.fixedExpenses;
		fe.growth = Number(this.manualFixedExpensesGrowth);
		fe.result = this.computedFixedExpenses;
		fe.resultSet = true;
		this.submit(doc);
	}
	public get nextPage() {
		if (this.editMode === 'fixedExpenses') {
			return '';
		}
		return 'ForecastModalVariableExpenses';
	}
	public get title() {
		return 'Expenses';
	}
	public get okTitle() {
		if (this.nextPage === '') {
			return 'Done';
		}
		return 'Next';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.fixedExpenses.resultSet && this.nextPage !== '';
	}
	public get okDisabled() {
		return this.showLoading;
	}
}
