
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';

@Component({
	components: {},
})
export default class ForecastModalVariableExpenses extends BaseForecastModalPage {
	public manualVariableExpenses = '0';

	public mounted() {
		this.manualVariableExpenses = this.doc.variableExpenses.sample.toString();
	}
	public onNext() {
		const doc = this.doc;
		const ve = this.doc.variableExpenses;
		ve.sample = Number(this.manualVariableExpenses);
		this.manualVariableExpenses = ve.sample.toString();
		ve.result = new Array(12).fill(ve.sample);
		this.submit(doc);
	}
	public get nextPage() {
		return '';
	}
	public get title() {
		return 'Additional Expenses Over Sales';
	}
	public get okTitle() {
		return 'Done';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.variableExpenses.resultSet && this.nextPage !== '';
	}
}
