
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import { FilteredDatabase } from '../../../worker/fd/FilteredDatabase';
import ForecastModalMonth from './components/ForecastModalMonth.vue';

@Component({
	components: { ForecastModalMonth },
})
export default class ForecastModalLoadSales extends BaseForecastModalPage {
	public manualSalesGrowth = '0';
	public showLoading = false;

	public get allMonthlySales() {
		const doc = this.doc;
		const growth = doc.sales.growth;

		if (doc.sales.type === 'grow') {
			const sync = doc.sales.syncResult;
			const months: number[] = [];
			const { isExponential, isFirstMonthFixed } = doc.sales;
			for (let i = 0; i < 12; i++) {
				if (isFirstMonthFixed && i === 0) {
					months[0] = sync[0];
				} else {
					const growthValue =
						!isExponential || i === 0
							? sync[0] * (growth / 100)
							: months[i - 1] * (growth / 100);
					months[i] = sync[i] + growthValue;
				}
				if (i < 11) {
					sync[i + 1] = months[i];
				}
			}
			return months;
		} else {
			// copy or flat
			const sync = doc.sales.syncResult;
			const months = [...sync];
			for (let i = 0; i < 12; i++) {
				months[i] = sync[i] * (1 + growth / 100);
			}
			return months;
		}
	}
	public get limitedMonthlySales() {
		return this.allMonthlySales.slice(0, this.period);
	}

	public async copySales() {
		const doc = this.doc;
		const mmt = moment(doc.sales.date);
		const syncResult: number[] = [];
		for (let i = 0; i < 12; i++) {
			const ref = FilteredDatabase.ref('transactions').month(
				mmt.year(),
				mmt.month(),
			);
			const salesHead = await ref.includes('accType', ['SA', 'SL']).get();
			const sales = await salesHead.getSum('amount');
			syncResult.push(-sales);
			mmt.add(1, 'month');
		}
		return syncResult;
	}
	public async loadSalesAverage() {
		const doc = this.doc;
		const date = doc.sales.dateRange;
		const ref = FilteredDatabase.ref('transactions').dateRange(date);
		const salesHead = await ref.includes('accType', ['SA', 'SL']).get();
		const sales = await salesHead.getSum('amount');
		const [d0, d1] = date.map((d) => moment(d));
		const diff = d1.diff(d0, 'month') + 1;
		return -sales / diff;
	}
	public async loadSalesOfMonth() {
		const doc = this.doc;
		const mmt = moment(doc.sales.date);
		const ref = FilteredDatabase.ref('transactions').month(
			mmt.year(),
			mmt.month(),
		);
		const salesHead = await ref.includes('accType', ['SA', 'SL']).get();
		const sales = await salesHead.getSum('amount');
		return -sales;
	}
	@Watch('manualSalesGrowth')
	public onSalesGrowthChanged(newVal: string) {
		const number = Number(newVal);
		this.doc.sales.growth = number;
	}

	public async fromPreviousPage(passData: any) {
		const doc = this.doc;
		if (passData && passData.resync) {
			this.showLoading = true;
			if (doc.sales.type === 'copy') {
				doc.sales.syncResult = await this.copySales();
			} else {
				let result = 0;
				if (doc.sales.resultType === 'average') {
					result = await this.loadSalesAverage();
				} else if (doc.sales.resultType === 'month') {
					result = await this.loadSalesOfMonth();
				}
				doc.sales.syncResult = new Array(12).fill(result);
			}
			doc.sales.syncAt = Date.now();
			doc.sales.resultSet = true;
			this.showLoading = false;
		}
	}

	public mounted() {
		const doc = this.doc;

		const result = doc.sales.growth;
		this.manualSalesGrowth = result === result ? result.toString() : '0';
	}
	public onNext() {
		const doc = this.doc;
		const sales = doc.sales;
		sales.growthSet = true;
		sales.growth = Number(this.manualSalesGrowth);
		sales.result = this.allMonthlySales;
		this.submit(doc);
	}
	public get nextPage() {
		if (this.editMode === 'sales') {
			return '';
		}
		return 'ForecastModalGrossProfit';
	}
	public get title() {
		return 'Month Sales Forecast';
	}
	public get okTitle() {
		if (this.nextPage === '') {
			return 'Done';
		}
		return 'Next';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.sales.growthSet && this.nextPage !== '';
	}
	public get okDisabled() {
		return this.showLoading;
	}
}
