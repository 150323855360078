
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({
	components: {},
})
export default class ForecastCard extends Vue {
	@Prop({ default: 'no-id' }) public readonly docId!: string;
	@Prop({ default: 'Untitled' }) public readonly title!: string;
	@Prop({ default: 0 }) public readonly createdAt!: number;
	@Prop({ default: false }) public readonly lastVisited!: boolean;
	// @Prop({ default: false }) public readonly unvisited!: boolean;
	public onDelete() {
		this.$emit('delete', this.docId);
	}
	public get date() {
		if (this.createdAt === 0) {
			return '';
		}
		return moment(this.createdAt).format('DD-MM-YYYY');
	}
}
