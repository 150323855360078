
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import accountx from '@/store/modules/accountx';
import gdbx from '@/store/modules/gdbx';
import { addComma } from '@/util/number';

@Component({
	components: {},
})
export default class ForecastModalMonth extends Vue {
	@Prop({ default: 'MONTH' }) public title!: string;
	@Prop({ default: 0 }) public value!: number;
	@Prop({ default: 0 }) public increase!: number;

	public get numDecimal() {
		return gdbx.numDecimal;
	}
	public get formatedValue() {
		return addComma(this.value, this.numDecimal, true, true);
	}
	public get formatedFooter() {
		return (
			(this.increase >= 0 ? '+' : '') +
			addComma(this.increase, this.numDecimal, true, true)
		);
	}
	public get increaseClass() {
		return this.increase >= 0 ? 'text-success' : 'text-danger';
	}
}
