import { db } from '@/store/firestore';
import userx from '@/store/modules/userx';
import { ForecastDoc } from '@/store/models.def';


export function newForecast(custom: any = {}): ForecastDoc {
	return {
		docId: db.collection('random').doc().id,
		title: 'Untitled',
		dateRangeSet: false,
		dateRange: [0, 0],
		isNew: true,
		createdBy: (userx.user && userx.user.email) || '',
		createdAt: Date.now(),
		lastEditedBy: (userx.user && userx.user.email) || '',
		lastEditedAt: 0,
		sales: {
			dateSet: false,
			dateRangeSet: false,
			date: 0,
			dateRange: [0, 0],
			type: 'copy',
			resultType: 'month',
			syncAt: Number.NaN,
			syncResult: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			resultSet: false,
			result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			growthSet: false,
			growth: 10,
			isFirstMonthFixed: false,
			isExponential: false,
		},
		grossProfit: {
			dateSet: false,
			dateRangeSet: false,
			date: 0,
			dateRange: [0, 0],
			type: 'average',
			syncAt: Number.NaN,
			growth: 0,
			syncResult: 0,
			resultSet: false,
			result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		variableExpenses: {
			sample: 0,
			resultSet: false,
			result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		fixedExpenses: {
			type: 'copy',
			dateRangeSet: false,
			dateRange: [0, 0],
			growth: 0,
			syncAt: Number.NaN,
			syncResult: [],
			resultSet: false,
			result: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
		},
		// ...custom,
	};
}
