
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';
import moment, { Moment } from 'moment';
import accountx from '../../store/modules/accountx';
import { ForecastDoc, StockBalanceCard } from '../../store/models.def';
import NameInput from '@/components/inputs/NameInput.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import { FilteredDatabase } from '../../worker/fd/FilteredDatabase';
import gdbx from '../../store/modules/gdbx';
import { db } from '../../store/firestore';
import { newForecast } from './newForecast';
import userx from '../../store/modules/userx';
import BaseForecastModalPage from './modalPages/BaseForecastModalPage.vue';
import ForecastModalTitle from './modalPages/ForecastModal_A_Title.vue';
import ForecastModalType from './modalPages/ForecastModal_B_Type.vue';
import ForecastModalCopy from './modalPages/ForecastModal_B11_Copy.vue';
import ForecastModalGrowFlat from './modalPages/ForecastModal_B12_GrowFlat.vue';
import ForecastModalLoadSales from './modalPages/ForecastModal_B2_LoadSales.vue';
import ForecastModalGrossProfit from './modalPages/ForecastModal_C_GrossProfit.vue';
import ForecastModalLoadGrossProfit from './modalPages/ForecastModal_C2_LoadGrossProfit.vue';
import ForecastModalFixedExpenses from './modalPages/ForecastModal_D_FixedExpenses.vue';
import ForecastModalLoadFixedExpenses from './modalPages/ForecastModal_D2_LoadFixedExpenses.vue';
import ForecastModalVariableExpenses from './modalPages/ForecastModal_E_VariableExpenses.vue';
import { wait } from '../../util/wait';
@Component({
	components: {
		NameInput,
		DateRangeFilter,
		DateFilter,
		ForecastModalTitle,
		ForecastModalGrossProfit,
		ForecastModalLoadGrossProfit,
		ForecastModalFixedExpenses,
		ForecastModalLoadFixedExpenses,
		ForecastModalVariableExpenses,
		ForecastModalType,
		ForecastModalCopy,
		ForecastModalGrowFlat,
		ForecastModalLoadSales,
	},
})
export default class ForecastModal extends Vue {
	@Prop({ default: newForecast }) public document!: ForecastDoc;
	public doc = JSON.parse(JSON.stringify(this.document));
	public editMode:
		| 'all'
		| 'sales'
		| 'grossProfit'
		| 'variableExpenses'
		| 'fixedExpenses' = 'all';

	public title: string = '';
	public okTitle: string = '';
	public nextPage: string = '';
	public skipPage: string = '';
	public showSkip: boolean = false;
	public okDisabled: boolean = false;

	public pageName = 'ForecastModalTitle';
	public passData: any = null;
	public history: string[] = [];
	public get showBack(): boolean {
		return this.history.length > 0;
	}

	public onSkip() {
		this.history.push(this.pageName);
		this.pageName = this.skipPage;
		this.passData = null;
	}
	public onBack() {
		const previousPage = this.history.pop();
		if (previousPage) {
			this.pageName = previousPage;
			this.passData = null;
		}
	}

	public onNext() {
		const doc = (this.$refs.page as BaseForecastModalPage).onNext();
	}
	public next(doc: ForecastDoc | null | void | false, passData: any) {
		if (doc) {
			this.doc = doc;
		}
		if (this.nextPage) {
			this.history.push(this.pageName);
			this.pageName = this.nextPage;
			this.passData = passData;
		} else {
			this.$emit('completed', this.doc);
			this.hide();
		}
	}
	public onPageMounted() {
		(this.$refs.page as BaseForecastModalPage).fromPreviousPage(this.passData);
	}
	public hide() {
		const modal = this.$refs.forecastModal as BModal;
		if (modal) {
			modal.hide();
		}
	}
	public async show(
		target:
			| 'all'
			| 'sales'
			| 'grossProfit'
			| 'variableExpenses'
			| 'fixedExpenses',
		currentDoc: ForecastDoc,
	) {
		this.doc = currentDoc;
		this.editMode = target;
		const modal = this.$refs.forecastModal as BModal;
		if (modal) {
			switch (target) {
				case 'sales':
					this.pageName = 'ForecastModalType';
					break;
				case 'grossProfit':
					this.pageName = 'ForecastModalGrossProfit';
					break;
				case 'variableExpenses':
					this.pageName = 'ForecastModalVariableExpenses';
					break;
				case 'fixedExpenses':
					this.pageName = 'ForecastModalFixedExpenses';
					break;
				default:
					this.pageName = 'ForecastModalTitle';
					break;
			}
			this.history = [];
			modal.show();
		}
	}
}
