
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import moment, { Moment } from 'moment';
import NameInput from '@/components/inputs/NameInput.vue';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';

@Component({
	components: {
		NameInput,
		DateRangeFilter,
	},
})
export default class ForecastModalTitle extends BaseForecastModalPage {
	public validateTitleNow = false;
	public manualDateRange: [number, number] = [0, 0];

	@Watch('manualDateRange')
	public onManualDateRangeChanged(newVal: [number, number]) {
		const [d0, d1] = newVal.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		if (period > 12) {
			this.manualDateRange[1] = d0
				.add(11, 'month')
				.endOf('month')
				.valueOf();
		}
	}

	public mounted() {
		// apparently subclass cannot directly get computed data from superclass
		// so must set in mounted
		if (!this.doc.dateRangeSet) {
			this.manualDateRange = this.suggestedForecastDateRange;
		} else {
			this.manualDateRange = this.doc.dateRange;
		}
	}
	public onNext() {
		const doc = this.doc;
		this.validateTitleNow = true;
		if (!doc.title) {
			return;
		}
		doc.dateRange = this.manualDateRange;
		doc.dateRangeSet = true;
		return this.submit(doc);
	}

	public get title() {
		return 'Title And Period';
	}
	public get nextPage() {
		return 'ForecastModalType';
		// return 'ForecastModalFirstMonth';
	}
}
