
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
@Component({
	components: { DateRangeFilter, DateFilter },
})
export default class ForecastModalCopy extends BaseForecastModalPage {
	public get selectedPeriod() {
		const d0 = moment(this.doc.sales.date);
		const d1 = d0.clone().add(this.period - 1, 'month');
		return `${d0.format('MMM YYYY')} ~ ${d1.format('MMM YYYY')}`;
	}

	public mounted() {
		const doc = this.doc;
		const dateRange = this.suggestedReferenceDateRange;
		const [d0, d1] = dateRange;
		if (!doc.sales.dateSet) {
			doc.sales.date = d0;
		}
	}
	public onNext() {
		const doc = this.doc;
		doc.sales.dateSet = true;
		this.submit(doc, { resync: true });
	}
	public get nextPage() {
		return 'ForecastModalLoadSales';
	}
	public get title() {
		return 'Choose Copy Period';
	}
	public get okTitle() {
		return 'Load';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.sales.resultSet;
	}
}
