
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
@Component({
	components: { DateRangeFilter, DateFilter },
})
export default class ForecastModalGrowFlat extends BaseForecastModalPage {
	public manualFirstMonth = '0';

	public mounted() {
		const doc = this.doc;
		const part = doc.sales;
		const result = part.syncResult;
		this.manualFirstMonth =
			result === result ? result[0].toFixed(gdbx.numDecimal) : '0';

		const dateRange = this.suggestedReferenceDateRange;

		const [d0, d1] = dateRange;
		if (!part.dateRangeSet) {
			part.dateRange = [d0, d1];
		}
		if (!part.dateSet) {
			part.date = d0;
		}
		if (
			!part.resultSet &&
			part.resultType !== 'manual' &&
			!this.hasTransactionPermission
		) {
			part.resultType = 'manual';
		}
	}
	public onNext() {
		const doc = this.doc;
		if (doc.sales.resultType === 'manual') {
			doc.sales.resultSet = true;
			doc.sales.syncResult = new Array(12).fill(Number(this.manualFirstMonth));
			this.manualFirstMonth = doc.sales.syncResult[0].toFixed(gdbx.numDecimal);
			this.submit(doc);
		} else if (this.hasTransactionPermission) {
			if (doc.sales.resultType === 'average') {
				doc.sales.dateRangeSet = true;
			} else {
				doc.sales.dateSet = true;
			}
			this.submit(doc, { resync: true });
		} else {
			// skip
			this.submit(this.document);
		}
	}
	public get nextPage() {
		return 'ForecastModalLoadSales';
	}
	public get title() {
		return this.doc.sales.type !== 'grow'
			? 'Sales Number Before Growth'
			: 'Monthly Average Sales Target';
	}
	public get label() {
		return this.doc.sales.type !== 'grow'
			? 'Derive the forecasted sales number before growth'
			: 'Derive the monthly average sales target';
	}
	public get okTitle() {
		if (this.doc.sales.resultType === 'manual') {
			return 'Next';
		}
		if (this.hasTransactionPermission) {
			return 'Load';
		}
		return 'Skip';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.sales.resultSet && this.okTitle !== 'Skip';
	}
}
