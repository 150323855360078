
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import { FilteredDatabase } from '../../../worker/fd/FilteredDatabase';
import ForecastModalMonth from './components/ForecastModalMonth.vue';
import { StockBalanceCard } from '../../../store/models.def';
import { roundToFixed } from '@/util/number';

@Component({
	components: { ForecastModalMonth },
})
export default class ForecastModalLoadGrossProfit extends BaseForecastModalPage {
	public manualGrossProfit = '0';
	public showLoading = false;

	public async loadGp() {
		const doc = this.doc;
		const mmt = moment(doc.grossProfit.date);
		const dateRange: [number, number] = [
			mmt.startOf('month').valueOf(),
			mmt.endOf('month').valueOf(),
		];
		const stockBalance = await FilteredDatabase.getStockMovement(dateRange);
		const ref = FilteredDatabase.ref('transactions').month(
			mmt.year(),
			mmt.month(),
		);
		const salesHead = await ref.clone().includes('accType', ['SA', 'SL']).get();
		const sales = await salesHead.getSum('amount');
		const costHead = await ref.clone().includes('accType', ['CO']).get();
		const cost = (await costHead.getSum('amount')) - stockBalance;
		return ((sales + cost) / sales) * 100;
	}
	public async loadGpAverage() {
		const doc = this.doc;
		const dateRange = doc.grossProfit.dateRange;
		const stockBalance = await FilteredDatabase.getStockMovement(dateRange);
		const ref = FilteredDatabase.ref('transactions').dateRange(dateRange);
		const salesHead = await ref.clone().includes('accType', ['SA', 'SL']).get();
		const sales = await salesHead.getSum('amount');
		const costHead = await ref.clone().includes('accType', ['CO']).get();
		const cost = (await costHead.getSum('amount')) - stockBalance;
		return ((sales + cost) / sales) * 100;
	}
	public get refGp() {
		return roundToFixed(this.doc.grossProfit.syncResult || 0, 2).toString();
	}

	public async fromPreviousPage(passData: any) {
		const doc = this.doc;
		if (passData && passData.resync) {
			this.showLoading = true;
			if (doc.grossProfit.type === 'average') {
				doc.grossProfit.syncResult = await this.loadGpAverage();
			} else {
				doc.grossProfit.syncResult = await this.loadGp();
			}
			this.manualGrossProfit = this.refGp;
			doc.grossProfit.syncAt = Date.now();
			this.showLoading = false;
		}
	}

	public mounted() {
		this.manualGrossProfit = this.refGp;
	}
	public onNext() {
		const doc = this.doc;
		const gp = this.doc.grossProfit;
		gp.syncResult = Number(this.manualGrossProfit);
		this.manualGrossProfit = gp.syncResult.toString();
		gp.result = new Array(12).fill(gp.syncResult);
		gp.resultSet = true;
		this.submit(doc);
	}
	public get nextPage() {
		if (this.editMode === 'grossProfit') {
			return '';
		}
		return 'ForecastModalFixedExpenses';
	}
	public get title() {
		return 'Gross Profit Rate';
	}
	public get okTitle() {
		if (this.nextPage === '') {
			return 'Done';
		}
		return 'Next';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.grossProfit.resultSet && this.nextPage !== '';
	}
	public get okDisabled() {
		return this.showLoading;
	}
}
