var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{staticClass:"super-rounded"},[_c('b-form-group',{attrs:{"label":"Enter a gross profit rate","label-class":"mb-3"}},[_c('b-form-radio-group',{model:{value:(_vm.doc.grossProfit.type),callback:function ($$v) {_vm.$set(_vm.doc.grossProfit, "type", $$v)},expression:"doc.grossProfit.type"}},[_c('b-form-radio',{attrs:{"value":"average","disabled":!_vm.hasTransactionPermission}},[_vm._v("Average gross profit rate of selected months")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.grossProfit.type!=='average',
							'text-gray-700': _vm.doc.grossProfit.type==='average',
						}},[_vm._v("Select gross profit rate from several months and calculate the average amount")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateRangeFilter',{attrs:{"showHeader":false,"showFooter":false,"dateType":'month',"filterId":"","disabled":_vm.doc.grossProfit.type!=='average'},model:{value:(_vm.doc.grossProfit.dateRange),callback:function ($$v) {_vm.$set(_vm.doc.grossProfit, "dateRange", $$v)},expression:"doc.grossProfit.dateRange"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"month","disabled":!_vm.hasTransactionPermission}},[_vm._v("Gross profit rate of selected month")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.grossProfit.type!=='month',
							'text-gray-700': _vm.doc.grossProfit.type==='month',
						}},[_vm._v("Copy gross profit rate from the selected month.")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateFilter',{attrs:{"disabled":_vm.doc.grossProfit.type!=='month',"dateType":'month',"filterId":""},model:{value:(_vm.doc.grossProfit.date),callback:function ($$v) {_vm.$set(_vm.doc.grossProfit, "date", $$v)},expression:"doc.grossProfit.date"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"manual"}},[_vm._v("Manual Input")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-sm",class:{
							'text-gray-400': _vm.doc.grossProfit.type!=='manual',
							'text-gray-700': _vm.doc.grossProfit.type==='manual',
						}},[_vm._v("Manually input value in next page")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }