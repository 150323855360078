
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class ForecastModalTypeButton extends Vue {
	@Prop({ default: 'copy' }) public type!: 'copy' | 'grow' | 'flat';
	@Prop({ default: '' }) public disabledLabel!: string;
	public svgIds = {
		grow: 'ees2bw2wg8u1',
		flat: 'e86r2073e77x1',
		copy: 'epdjp4loniu1',
	};
	public getSvg() {
		const a = this.$refs.svg;
		if (!a) {
			return null;
		}
		const b = (a as any).contentDocument;
		if (!b) {
			return null;
		}
		const c = b.getElementById(this.svgIds[this.type]);
		return c;
	}

	public get thumbnailUrl() {
		return `img/forecast/forecast-thumbnails-${this.type}.svg`;
	}
	public get title() {
		return this.type === 'copy'
			? 'Reference From Historical Sales'
			: this.type === 'grow'
			? 'Monthly Growth Projection'
			: 'Monthly Average Sales Target';
	}
	public get desc() {
		return this.type === 'copy'
			? 'Forecast using previous year sales data with adjustable growth rate'
			: this.type === 'grow'
			? 'Project a monthly growing target linearly or exponentially'
			: 'Set a monthly average goal for sales';
	}

	public onMouseOver() {
		if (this.disabledLabel) {
			return;
		}
		const svg = this.getSvg();
		if (svg) {
			svg.dispatchEvent(new Event('mouseenter'));
		}
	}
	public onMouseOut() {
		if (this.disabledLabel) {
			return;
		}
		const svg = this.getSvg();
		if (svg) {
			svg.dispatchEvent(new Event('mouseleave'));
		}
	}
	public onClick() {
		if (!this.disabledLabel) {
			this.$emit('click');
		}
	}
}
