
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { newForecast } from '../newForecast';
import { ForecastDoc } from '../../../store/models.def';
import moment, { Moment } from 'moment';
import gdbx from '../../../store/modules/gdbx';
import accountx from '@/store/modules/accountx';

@Component({
	components: {},
})
export default class BaseForecastModalPage extends Vue {
	@Prop({ default: newForecast }) public document!: ForecastDoc;
	@Prop({ default: 'all' }) public editMode!:
		| 'all'
		| 'sales'
		| 'grossProfit'
		| 'variableExpenses'
		| 'fixedExpenses';
	public doc: ForecastDoc = JSON.parse(JSON.stringify(this.document));

	public get hasTransactionPermission() {
		return accountx.myPermissions && accountx.myPermissions.transactions;
	}
	public get requireTransactionLabel() {
		return 'Require "GL Transactions" Permission';
	}

	public get suggestedForecastDateRange(): [number, number] {
		return [
			moment().add(1, 'month').startOf('month').valueOf(),
			moment().add(12, 'month').endOf('month').valueOf(),
		];
	}
	public get suggestedReferenceDateRange(): [number, number] {
		const doc = this.doc;
		if (doc.sales.dateRangeSet) {
			return doc.sales.dateRange;
		}
		if (doc.sales.dateSet) {
			return [
				doc.sales.date,
				moment(doc.sales.date).add(11, 'month').endOf('month').valueOf(),
			];
		}
		if (doc.fixedExpenses.dateRangeSet) {
			return doc.fixedExpenses.dateRange;
		}
		if (doc.grossProfit.dateRangeSet) {
			return doc.grossProfit.dateRange;
		}
		if (doc.dateRangeSet) {
			return [
				moment(doc.dateRange[0]).add(-1, 'year').startOf('month').valueOf(),
				moment(doc.dateRange[1]).add(-1, 'year').endOf('month').valueOf(),
			];
		}
		return [
			moment().add(-1, 'year').add(1, 'month').startOf('month').valueOf(),
			moment().add(-1, 'year').add(12, 'month').endOf('month').valueOf(),
		];
	}
	public get period() {
		const dateRange = this.doc.dateRangeSet
			? this.doc.dateRange
			: this.suggestedForecastDateRange;
		const [d0, d1] = dateRange.map((d) => moment(d));
		return d1.diff(d0, 'month') + 1;
	}
	public get months() {
		const dateRange = this.doc.dateRangeSet
			? this.doc.dateRange
			: this.suggestedForecastDateRange;
		const d0 = moment(dateRange[0]);
		const result: string[] = [];
		for (let i = 0; i < this.period; i++) {
			result.push(d0.clone().format('MMM YYYY'));
			d0.add(1, 'month');
		}
		return result;
	}
	public numberFormatter = (str: string) => Number(str);
	public currencyFormatter = (s: string) => Number(s).toFixed(gdbx.numDecimal);

	public onNext() {}
	public submit(doc: ForecastDoc, passData?: any) {
		this.$emit('next', doc, passData);
	}

	public fromPreviousPage(data: any) {}

	public get title() {
		return '';
	}
	public get okTitle() {
		return 'Next';
	}
	public get nextPage() {
		return '';
	}
	public get skipPage() {
		return this.nextPage;
	}
	public get showBack() {
		return false;
	}
	public get showSkip() {
		return false;
	}
	public get okDisabled() {
		return false;
	}
	@Watch('title', { immediate: true })
	public onTitleChange(newVal: string) {
		this.$emit('title', newVal);
	}
	@Watch('okTitle', { immediate: true })
	public onOkTitleChange(newVal: string) {
		this.$emit('okTitle', newVal);
	}
	@Watch('nextPage', { immediate: true })
	public onNextPageChange(newVal: string) {
		this.$emit('nextPage', newVal);
	}
	@Watch('skipPage', { immediate: true })
	public onSkipPageChange(newVal: string) {
		this.$emit('skipPage', newVal);
	}
	@Watch('showBack', { immediate: true })
	public onShowCBackhange(newVal: boolean) {
		this.$emit('showBack', newVal);
	}
	@Watch('showSkip', { immediate: true })
	public onShowSkipChange(newVal: boolean) {
		this.$emit('showSkip', newVal);
	}
	@Watch('okDisabled', { immediate: true })
	public onOkDisabledChange(newVal: boolean) {
		this.$emit('okDisabled', newVal);
	}
}
