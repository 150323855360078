
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import accountx from '@/store/modules/accountx';
@Component({
	components: { DateRangeFilter, DateFilter },
})
export default class ForecastModalFixedExpenses extends BaseForecastModalPage {
	public manualFixedExpenses = '';

	public mounted() {
		const doc = this.doc;
		const part = doc.fixedExpenses;
		if (!part.dateRangeSet) {
			part.dateRange = [
				this.suggestedReferenceDateRange[0],
				this.suggestedReferenceDateRange[1],
			];
		}
		this.manualFixedExpenses = (part.result[0] || 0).toFixed(gdbx.numDecimal);
		if (
			!part.resultSet &&
			part.type !== 'manual' &&
			!this.hasTransactionPermission
		) {
			part.type = 'manual';
		}
	}
	public onNext() {
		const doc = this.doc;
		const fe = this.doc.fixedExpenses;
		if (fe.type === 'manual') {
			fe.resultSet = true;
			fe.result = new Array(12).fill(Number(this.manualFixedExpenses));
			this.submit(doc);
		} else if (this.hasTransactionPermission) {
			fe.dateRangeSet = true;
			this.submit(doc, { resync: true });
		} else {
			// skip
			this.submit(this.document);
		}
	}
	public get nextPage() {
		if (this.doc.fixedExpenses.type !== 'manual') {
			return 'ForecastModalLoadFixedExpenses';
		}
		// if manual
		if (this.editMode === 'fixedExpenses') {
			// done
			return '';
		}
		return 'ForecastModalVariableExpenses';
	}
	public get title() {
		return 'Expenses';
	}
	public get okTitle() {
		if (this.nextPage === '') {
			return 'Done';
		}
		if (this.doc.fixedExpenses.type === 'manual') {
			return 'Next';
		}
		if (this.hasTransactionPermission) {
			return 'Load';
		}
		return 'Skip';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return (
			this.doc.fixedExpenses.resultSet &&
			this.nextPage !== '' &&
			this.okTitle !== 'Skip'
		);
	}
}
