
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import ForecastModalTypeButton from './components/ForecastModalTypeButton.vue';
@Component({
	components: { ForecastModalTypeButton },
})
export default class ForecastModalType extends BaseForecastModalPage {
	public setType(type: 'copy' | 'flat' | 'grow') {
		this.doc.sales.type = type;
	}

	public mounted() {
		const doc = this.doc;
		const part = doc.sales;
		if (
			!part.resultSet &&
			part.type === 'copy' &&
			!this.hasTransactionPermission
		) {
			part.type = 'grow';
		}
	}
	public onNext() {
		const doc = this.doc;
		if (this.doc.sales.type !== this.document.sales.type) {
			this.doc.sales.resultSet = false;
		}
		this.submit(doc);
	}
	public get nextPage() {
		if (this.okTitle === 'Skip') {
			return this.skipPage;
		}
		return this.doc.sales.type === 'copy'
			? 'ForecastModalCopy'
			: 'ForecastModalGrowFlat';
	}
	public get skipPage() {
		return 'ForecastModalLoadSales';
	}
	public get title() {
		return `Select Forecast Type`;
	}
	public get okTitle() {
		if (
			!this.hasTransactionPermission &&
			this.doc.sales.type === 'copy' &&
			this.doc.sales.resultSet
		) {
			return 'Skip';
		}
		return 'Next';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.sales.resultSet && this.okTitle !== 'Skip';
	}
}
