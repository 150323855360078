var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{staticClass:"super-rounded"},[_c('b-form-group',{attrs:{"label":_vm.label,"label-class":"mb-3"}},[_c('b-form-radio-group',{model:{value:(_vm.doc.sales.resultType),callback:function ($$v) {_vm.$set(_vm.doc.sales, "resultType", $$v)},expression:"doc.sales.resultType"}},[_c('b-form-radio',{attrs:{"value":"month","disabled":!_vm.hasTransactionPermission}},[_vm._v("Sales of selected month")]),_c('div',{staticClass:"pl-4 mb-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.sales.resultType!=='month',
							'text-gray-700': _vm.doc.sales.resultType==='month',
						}},[_vm._v("Copy sales figure from the selected month.")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateFilter',{attrs:{"disabled":_vm.doc.sales.resultType!=='month',"dateType":'month',"filterId":""},model:{value:(_vm.doc.sales.date),callback:function ($$v) {_vm.$set(_vm.doc.sales, "date", $$v)},expression:"doc.sales.date"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"average","disabled":!_vm.hasTransactionPermission}},[_vm._v("Average of selected months")]),_c('div',{staticClass:"pl-4 mb-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.sales.resultType!=='average',
							'text-gray-700': _vm.doc.sales.resultType==='average',
						}},[_vm._v("Select sales figure from several months and calculate the average amount.")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateRangeFilter',{attrs:{"showHeader":false,"showFooter":false,"dateType":'month',"filterId":"","disabled":_vm.doc.sales.resultType!=='average'},model:{value:(_vm.doc.sales.dateRange),callback:function ($$v) {_vm.$set(_vm.doc.sales, "dateRange", $$v)},expression:"doc.sales.dateRange"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"manual"}},[_vm._v("Manual Input")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.sales.resultType!=='manual',
							'text-gray-700': _vm.doc.sales.resultType==='manual',
						}},[_vm._v("Manually input value")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('b-form-input',{staticClass:"right-input",attrs:{"disabled":_vm.doc.sales.resultType!=='manual',"lazy-formatter":"","formatter":_vm.currencyFormatter,"type":"number"},model:{value:(_vm.manualFirstMonth),callback:function ($$v) {_vm.manualFirstMonth=$$v},expression:"manualFirstMonth"}})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }