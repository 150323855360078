
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import LineChartFilterable from '../../filterables/LineChartFilterable.vue';
import FilterWidget from '../FilterWidget.vue';
import uuid from 'uuid';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	PermissionsGroup,
} from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import * as echarts from 'echarts';
import moment, { Moment } from 'moment';
import { GobiColor } from '@/helpers/color';
import { TooltipFormatter } from '../../elements/charts/helpers/tooltipFormatter';

@Component({
	components: {
		LineChartFilterable,
	},
})
export default class ForecastCompareLineChart extends FilterWidget {
	@Prop({ default: () => [] }) public forecastSales!: number[];
	@Prop({ default: () => [] }) public forecastNetProfits!: number[];

	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}

	public sales: number[] = [];
	public grossProfits: number[] = [];
	public expenses: number[] = [];

	public get dateFormatted() {
		return this.selectedDateRange;
	}
	public get netProfits() {
		return this.grossProfits.map(
			(grossProfits, i) => grossProfits - this.expenses[i],
		);
	}
	public get monthValues() {
		const [d0, d1] = this.selectedDateRange.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		const result: Moment[] = [];
		for (let i = 0; i < period; i++) {
			result.push(d0.clone());
			d0.add(1, 'month');
		}
		return result;
	}
	public get months() {
		return this.monthValues.map((m) => m.format('MMM YYYY'));
	}

	public filterIds: Array<
		'dateAsOf' | 'dateRange' | 'stockItems' | 'agents' | 'customers' | 'suppliers'
	> = ['dateRange'];

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get formatter() {
		return TooltipFormatter.lineChart;
	}

	public get expensiveHook() {
		const { selectedDateRange } = this;
		return JSON.stringify([selectedDateRange]);
	}

	public async expensiveCalc() {
		const jobId = (this.currentJobId = uuid.v4());
		// #1 task
		let pa = 0.25;
		let pb = 0;

		const ref = FilteredDatabase.ref('transactions').dateRange(
			this.selectedDateRange,
		);

		const sales = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['SA', 'SL']).get();
				return -(await head.getSum('amount'));
			},
		);
		this.sales = sales.map((dd) => dd.sum);

		pb += pa;
		pa = 0.25;
		const cost = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['CO']).get();
				return -(await head.getSum('amount'));
			},
		);

		pb += pa;
		pa = 0.25;
		const stockBalances = await Promise.all(
			this.monthValues.map(async (m) => {
				const mmt = m.clone();
				const dateRange: [number, number] = [
					mmt.startOf('month').valueOf(),
					mmt.endOf('month').valueOf(),
				];
				return FilteredDatabase.getStockMovement(dateRange);
			}),
		);
		this.grossProfits = cost.map(
			(co, i) => this.sales[i] + co.sum + stockBalances[i],
		);
		this.generateLoadingText(pa + pb);

		pb += pa;
		pa = 0.25;
		const expenses = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['EP']).get();
				return await head.getSum('amount');
			},
		);
		this.expenses = expenses.map((dd) => dd.sum);

		this.saveHistory('sales', 'grossProfits', 'expenses');
		// this.xAxisData = [...Array(n + 1).keys()].slice(1);

		// this.series = result;
	}

	public get xAxisData() {
		return this.months;
	}
	public get series(): echarts.ECharts[] | any {
		const result = [
			{
				name: 'Predicted Sales',
				type: 'line',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR1,
				lineStyle: {
					type: 'dashed',
					opacity: 0.5,
				},
				data: this.forecastSales,
			},
			{
				name: 'Actual Sales',
				type: 'line',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR1,
				data: this.sales,
			},
			{
				name: 'Predicted Net Profit',
				type: 'line',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR5,
				lineStyle: {
					type: 'dashed',
					opacity: 0.8,
				},
				data: this.forecastNetProfits,
			},
			{
				name: 'Actual Net Profit',
				type: 'line',
				smooth: true,
				barWidth: '40%',
				color: GobiColor.COLOR5,
				data: this.netProfits,
			},
		];
		return result;
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
