
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import BaseForecastModalPage from './BaseForecastModalPage.vue';
import gdbx from '@/store/modules/gdbx';
import moment from 'moment';
import DateRangeFilter from '@/components/reports-v2/components/filters/DateRangeFilter.vue';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
@Component({
	components: { DateRangeFilter, DateFilter },
})
export default class ForecastModalGrossProfit extends BaseForecastModalPage {
	public mounted() {
		const doc = this.doc;
		const part = doc.grossProfit;
		if (!part.dateRangeSet) {
			part.dateRange = [
				this.suggestedReferenceDateRange[0],
				this.suggestedReferenceDateRange[1],
			];
		}
		if (!part.dateSet) {
			part.date = this.suggestedReferenceDateRange[0];
		}
		if (
			!part.resultSet &&
			part.type !== 'manual' &&
			!this.hasTransactionPermission
		) {
			part.type = 'manual';
		}
	}
	public onNext() {
		const doc = this.doc;
		if (doc.grossProfit.type === 'manual') {
			this.submit(doc);
		} else if (this.hasTransactionPermission) {
			if (doc.grossProfit.type === 'average') {
				doc.grossProfit.dateRangeSet = true;
			} else {
				doc.grossProfit.dateRangeSet = true;
			}
			this.submit(doc, { resync: true });
		} else {
			// skip
			this.submit(this.document);
		}
	}
	public get nextPage() {
		return 'ForecastModalLoadGrossProfit';
	}
	public get title() {
		return 'Gross Profit Rate';
	}
	public get okTitle() {
		if (this.doc.grossProfit.type === 'manual') {
			return 'Next';
		}
		if (this.hasTransactionPermission) {
			return 'Load';
		}
		return 'Skip';
	}
	public get showSkip() {
		if (this.doc.isNew) {
			return false;
		}
		return this.doc.grossProfit.resultSet && this.okTitle !== 'Skip';
	}
}
