
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ForecastModal from './ForecastModal.vue';
import { newForecast } from './newForecast';
import userx from '../../store/modules/userx';
import { ForecastDoc, EditState } from '../../store/models.def';
import gdbx from '../../store/modules/gdbx';
import MutableInputContainer from '@/components/inputs/MutableInputContainer.vue';
import DateRangeFilter from '../reports-v2/components/filters/DateRangeFilter.vue';
import moment from 'moment';
import { db } from '../../store/firestore';
import CompareTable from '@/components/reports-v2/components/codedWidgets/forecast/CompareTable.vue';
import CompareLineChart from '@/components/reports-v2/components/codedWidgets/forecast/CompareLineChart.vue';
import accountx from '@/store/modules/accountx';
@Component({
	components: {
		ForecastModal,
		MutableInputContainer,
		DateRangeFilter,
		CompareTable,
		CompareLineChart,
	},
})
export default class ForecastEditor extends Vue {
	public get hasChange() {
		return (this.document && this.oldDoc !== JSON.stringify(this.doc)) || false;
	}
	public get canSave() {
		return accountx.myPermissions && accountx.myPermissions.forecastWrite;
	}

	public get lastText() {
		return this.doc.lastEditedAt !== 0
			? `Last edited by ${this.doc.lastEditedBy} at ${moment(
					this.doc.lastEditedAt,
			  ).format('DD MMM YYYY hh:mm:ss')}`
			: `Created by ${this.doc.createdBy} at ${moment(
					this.doc.createdAt,
			  ).format('DD MMM YYYY hh:mm:ss')}`;
	}
	public get grossProfitValues() {
		const gp = this.grossProfits.map((n) => Number(n) / 100);
		return this.sales.map((sales, i) => Number(sales) * gp[i]);
	}
	public get variableExpensesValues() {
		const ve = this.variableExpenses.map((n) => Number(n) / 100);
		return this.sales.map((sales, i) => Number(sales) * ve[i]);
	}
	public get profits() {
		const fe = this.fixedExpenses.map((n) => Number(n));
		const ve = this.variableExpensesValues;
		return this.grossProfitValues.map((gp, i) => gp - fe[i] - ve[i]);
	}
	public get grossProfitTexts() {
		return this.grossProfitValues.map((n) => n.toFixed(gdbx.numDecimal));
	}
	public get variableExpensesTexts() {
		return this.variableExpensesValues.map((n) => n.toFixed(gdbx.numDecimal));
	}
	public get profitTexts() {
		return this.profits.map((n) => n.toFixed(gdbx.numDecimal));
	}

	public get allNumbers() {
		return this.sales.concat(
			this.grossProfits,
			this.variableExpenses,
			this.fixedExpenses,
			this.profitTexts,
			this.variableExpensesTexts,
			this.grossProfitTexts,
		);
	}

	public get months() {
		const mmt = moment(this.manualDateRange[0]);
		const result: string[] = [];
		for (let i = 0; i < this.period; i++) {
			result.push(mmt.format('MMM YYYY'));
			mmt.add(1, 'month');
		}
		return result;
	}
	public get expenses() {
		return this.variableExpensesValues.map(
			(ve, i) => Number(ve) + Number(this.fixedExpenses[i]),
		);
	}
	@Prop() public document!: ForecastDoc | null;

	public doc: ForecastDoc = !this.document
		? newForecast()
		: JSON.parse(JSON.stringify(this.document));

	public sales: string[] = [];
	public grossProfits: string[] = [];
	public variableExpenses: string[] = [];
	public fixedExpenses: string[] = [];
	public oldDoc = JSON.stringify(this.document);

	public manualDateRange: [number, number] = [
		moment().add(1, 'month').startOf('month').valueOf(),
		moment().add(12, 'month').endOf('month').valueOf(),
	];
	public period = 12;

	public fillZeros(list: string[]) {
		const period = this.period;
		for (let i = list.length; i < period; i++) {
			list[i] = '0';
		}
		return list;
	}
	@Watch('doc', { immediate: true })
	public onDocChanged(newVal: ForecastDoc, oldVal: ForecastDoc) {
		if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
			return;
		}
		if (newVal.dateRangeSet) {
			this.manualDateRange = newVal.dateRange;
		}
		this.resetFields();
	}
	@Watch('manualDateRange')
	public onManualDateRangeChanged(newVal: [number, number]) {
		const [d0, d1] = newVal.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		if (period > 12) {
			this.manualDateRange[1] = d0.add(11, 'month').endOf('month').valueOf();
			this.period = 12;
		} else {
			this.period = period;
		}
		this.doc.dateRange = this.manualDateRange;
		this.doc.dateRangeSet = true;
		this.resetFields();
	}
	public resetFields() {
		this.sales = this.fillZeros(
			this.doc.sales.result
				.slice(0, this.period)
				.map((n) => n.toFixed(gdbx.numDecimal)),
		);
		this.grossProfits = this.fillZeros(
			this.doc.grossProfit.result
				.slice(0, this.period)
				.map((n) => n.toString()),
		);
		this.variableExpenses = this.fillZeros(
			this.doc.variableExpenses.result
				.slice(0, this.period)
				.map((n) => n.toString()),
		);
		this.fixedExpenses = this.fillZeros(
			this.doc.fixedExpenses.result
				.slice(0, this.period)
				.map((n) => n.toFixed(gdbx.numDecimal)),
		);
	}
	@Watch('allNumbers')
	public onAllNumbersChanged(newVal: string[]) {
		let maxLen = 0;
		for (const number of newVal) {
			const len = number.length;
			if (maxLen < len) {
				maxLen = len;
			}
		}
		const ref = this.$refs.forecastTable as Vue;
		const table = ref.$el.children[0] as HTMLTableElement;
		const minWidth =
			Math.ceil((maxLen * 0.8 * this.months.length) / 10) * 10 + 8;
		table.style.minWidth = `${minWidth}rem`;
	}

	public showModal(
		target:
			| 'all'
			| 'sales'
			| 'grossProfit'
			| 'variableExpenses'
			| 'fixedExpenses',
	) {
		const modal = this.$refs.forecastModal as ForecastModal;
		if (modal) {
			modal.show(target, this.doc);
		}
	}
	@Watch('fixedExpenses')
	public onFixedExpensesChanged() {
		this.merge(this.fixedExpenses, this.doc.fixedExpenses.result);
	}
	@Watch('variableExpenses')
	public onVariableExpensesChanged() {
		this.merge(this.variableExpenses, this.doc.variableExpenses.result);
	}
	@Watch('grossProfits')
	public onGrossProfitsChanged() {
		this.merge(this.grossProfits, this.doc.grossProfit.result);
	}
	@Watch('sales')
	public onSalesChanged() {
		this.merge(this.sales, this.doc.sales.result);
	}

	public merge(fields: string[], list: number[]) {
		for (let i = 0; i < fields.length; i++) {
			list[i] = Number(fields[i]);
		}
		this.doc = { ...this.doc };
	}

	public onSave() {
		const doc = this.doc;
		doc.isNew = false;
		doc.lastEditedAt = Date.now();
		doc.lastEditedBy = (userx.user && userx.user.email) || '';
		this.$emit('save', this.doc);
		this.oldDoc = JSON.stringify(this.doc);
	}

	public mounted() {
		this.resetFields();
		const modal = this.$refs.forecastModal as ForecastModal;
		if (modal && this.doc.isNew) {
			modal.show('all', this.doc);
		}
	}

	public onModalCompleted(doc: ForecastDoc) {
		this.doc = doc;
	}
	// public beforeRouteEnter(to, from, next) {
	// 	next((vm) => {
	// 		if (!vm.document) {
	// 			next({ name: 'Forecast' });
	// 		} else {
	// 			next();
	// 		}
	// 	});
	// }
	// public async beforeRouteLeave(to, from, next) {
	// 	console.log('beforeRouteLeave', this.hasChange);
	// 	if (this.hasChange) {
	// 		const value = await this.$bvModal.msgBoxConfirm(
	// 			'Are you sure you want to leave without changes unsaved?',
	// 			{
	// 				size: 'sm',
	// 				cancelVariant: 'link-gray',
	// 				okVariant: 'warning',
	// 				okTitle: 'Yes',
	// 				cancelTitle: 'No',
	// 			},
	// 		);
	// 		if (value) {
	// 			next();
	// 		} else {
	// 			next(false);
	// 		}
	// 	} else {
	// 		next();
	// 	}
	// }

	public async stopPageLeave() {
		if (this.hasChange) {
			const value = await this.$bvModal.msgBoxConfirm(
				'Are you sure you want to leave without changes unsaved?',
				{
					size: 'sm',
					cancelVariant: 'link-gray',
					okVariant: 'warning',
					okTitle: 'Yes',
					cancelTitle: 'No',
				},
			);
			if (value) {
				return false;
			} else {
				return true;
			}
		} else {
			return false;
		}
	}
}
