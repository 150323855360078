var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{staticClass:"super-rounded"},[_c('b-form-group',{attrs:{"label":`What are the forecasted expenses`,"label-class":"mb-3"}},[_c('b-form-radio-group',{model:{value:(_vm.doc.fixedExpenses.type),callback:function ($$v) {_vm.$set(_vm.doc.fixedExpenses, "type", $$v)},expression:"doc.fixedExpenses.type"}},[_c('b-form-radio',{attrs:{"value":"copy","disabled":!_vm.hasTransactionPermission}},[_vm._v("Copy from selected months")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.fixedExpenses.type!=='copy',
							'text-gray-700': _vm.doc.fixedExpenses.type==='copy',
						}},[_vm._v("Copy expenses figure from the selected month. (add growth rate on the next page)")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateRangeFilter',{attrs:{"showHeader":false,"showFooter":false,"dateType":'month',"filterId":"","disabled":_vm.doc.fixedExpenses.type!=='copy' || !_vm.hasTransactionPermission},model:{value:(_vm.doc.fixedExpenses.dateRange),callback:function ($$v) {_vm.$set(_vm.doc.fixedExpenses, "dateRange", $$v)},expression:"doc.fixedExpenses.dateRange"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"average","disabled":!_vm.hasTransactionPermission}},[_vm._v("Average of selected months")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasTransactionPermission),expression:"!hasTransactionPermission"}],staticClass:"col-12 text-sm text-danger"},[_vm._v(_vm._s(_vm.requireTransactionLabel))]),_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.fixedExpenses.type!=='average',
							'text-gray-700': _vm.doc.fixedExpenses.type==='average',
						}},[_vm._v("Select expenses figure from several months and calculate the average amount (add growth rate on the next page)")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('div',[_c('DateRangeFilter',{attrs:{"showHeader":false,"showFooter":false,"dateType":'month',"filterId":"","disabled":_vm.doc.fixedExpenses.type!=='average' || !_vm.hasTransactionPermission},model:{value:(_vm.doc.fixedExpenses.dateRange),callback:function ($$v) {_vm.$set(_vm.doc.fixedExpenses, "dateRange", $$v)},expression:"doc.fixedExpenses.dateRange"}})],1)])])]),_c('b-form-radio',{attrs:{"value":"manual"}},[_vm._v("Manual Input")]),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 text-sm",class:{
							'text-gray-400': _vm.doc.fixedExpenses.type!=='manual',
							'text-gray-700': _vm.doc.fixedExpenses.type==='manual',
						}},[_vm._v("Manually input value")]),_c('div',{staticClass:"col-12 col-md-6 d-flex justify-content-end"},[_c('b-form-input',{staticClass:"right-input",attrs:{"disabled":_vm.doc.fixedExpenses.type!=='manual',"lazy-formatter":"","formatter":_vm.currencyFormatter,"type":"number"},model:{value:(_vm.manualFixedExpenses),callback:function ($$v) {_vm.manualFixedExpenses=$$v},expression:"manualFixedExpenses"}})],1)])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }