
import { Vue, Component, Prop } from 'vue-property-decorator';
import BaseInput from '@/components/inputs/BaseInput.vue';


@Component
export default class NameInput extends BaseInput {

	@Prop({default: true}) public readonly showInvalid!: boolean;
	@Prop({default: 'Enter your name'}) public readonly placeholder!: string;
	@Prop({default: 3}) public readonly length!: number;

	public input: string = '';

	get invalidError() {
		return `Enter at least ${this.length} letter${this.length > 1 ? 's' : ''}.`;
	}

	public validate(value: string) {
		if (value === null) { return false; }
		return value.length >= this.length;
	}
}
