
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '../../filterables/TableFilterable.vue';
import FilterWidget from '../FilterWidget.vue';
import uuid from 'uuid';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	GroupNameSumPair,
	ValueText,
	DimensionData,
	StockBalanceCard,
	PermissionsGroup,
 } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';
import { FilteredDatabaseHead } from '../../../../../worker/fd/FilteredDatabaseHead';
import { Dictionary } from 'vue-router/types/router';
import { addComma } from '../../../../../util/number';
import { TableItemFormatter } from '../../elements/charts/helpers/tableItemFormatter';
import moment, { Moment } from 'moment';
@Component({
	components: {
		TableFilterable,
	},
})
export default class ForecastCompareTable extends FilterWidget {
	@Prop({ default: () => [] }) public forecastSales!: number[];
	@Prop({ default: () => [] }) public forecastGrossProfits!: number[];
	@Prop({ default: () => [] }) public forecastExpenses!: number[];
	@Prop({ default: () => [] }) public forecastNetProfits!: number[];

	public sales: number[] = [];
	public grossProfits: number[] = [];
	public expenses: number[] = [];

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = ['dateRange'];

	public get permissionIds(): PermissionsGroup[] {
		return ['transactions'];
	}
	public get dateFormatted() {
		return this.selectedDateRange;
	}
	public get monthValues() {
		const [d0, d1] = this.selectedDateRange.map((d) => moment(d));
		const period = d1.diff(d0, 'month') + 1;
		const result: Moment[] = [];
		for (let i = 0; i < period; i++) {
			result.push(d0.clone());
			d0.add(1, 'month');
		}
		return result;
	}
	public get months() {
		return this.monthValues.map((m) => m.format('MMM YYYY'));
	}
	public get expensiveHook() {
		const { selectedDateRange } = this;
		return JSON.stringify([selectedDateRange]);
	}

	public async expensiveCalc() {
		const jobId = (this.currentJobId = uuid.v4());
		// #1 task
		let pa = 0.25;
		let pb = 0;

		const ref = FilteredDatabase.ref('transactions').dateRange(
			this.selectedDateRange,
		);

		const sales = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['SA', 'SL']).get();
				return -(await head.getSum('amount'));
			},
		);
		this.sales = sales.map((dd) => dd.sum);

		pb += pa;
		pa = 0.25;
		const cost = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['CO']).get();
				return -(await head.getSum('amount'));
			},
		);

		pb += pa;
		pa = 0.25;
		const stockMovements = await Promise.all(
			this.monthValues.map(async (m) => {
				return await FilteredDatabase.getStockMovement([
					m.valueOf(),
					m.clone().endOf('month').valueOf(),
				]);
			}),
		);
		this.grossProfits = cost.map(
			(co, i) => this.sales[i] + co.sum + stockMovements[i],
		);
		this.generateLoadingText(pa + pb);

		pb += pa;
		pa = 0.25;
		const expenses = await this._loadDimensionByPeriod(
			ref,
			'month',
			pa,
			pb,
			async (reff, paa, pbb) => {
				const head = await reff.includes('accType', ['EP']).get();
				return await head.getSum('amount');
			},
		);
		this.expenses = expenses.map((dd) => dd.sum);

		this.saveHistory('sales', 'grossProfits', 'expenses');
	}

	public get netProfits() {
		return this.grossProfits.map(
			(grossProfits, i) => grossProfits - this.expenses[i],
		);
	}

	public get tableFields() {
		return [
			{
				key: 'key',
				stickyColumn: true,
				label: '',
				variant: 'light font-weight-bold',
			},
			...this.months.map((month) => ({
				key: month,
			})),
			{ key: 'total', variant: 'forecast-total' },
			{ key: 'average' },
		];
	}

	public get tableItems() {
		const monthTotalAvg = [...this.months, 'total', 'average'];
		function addTotalAvg(array: number[] | string[]) {
			let total = 0;
			const len = array.length;
			const added: number[] = [];
			for (const num of array) {
				const n = Number(num) || 0;
				total += n;
				added.push(n);
			}
			added.push(total, total / len);
			return added;
		}
		// const processed = {
		// 	sales: addTotalAvg(this.sales),
		// 	grossProfits: addTotalAvg(this.grossProfits),
		// 	expenses: addTotalAvg(this.expenses),
		// 	netProfits: addTotalAvg(this.netProfits),
		// 	forecastSales: addTotalAvg(this.forecastSales),
		// 	forecastGrossProfits: addTotalAvg(this.forecastGrossProfits),
		// 	forecastExpenses: addTotalAvg(this.forecastExpenses),
		// 	forecastNetProfits: addTotalAvg(this.forecastNetProfits),
		// };
		const processed = {
			sales: addTotalAvg(this.sales),
			grossProfits: addTotalAvg(this.grossProfits),
			expenses: addTotalAvg(this.expenses),
			netProfits: addTotalAvg(this.netProfits),
			forecastSales: addTotalAvg(this.forecastSales),
			forecastGrossProfits: addTotalAvg(this.forecastGrossProfits),
			forecastExpenses: addTotalAvg(this.forecastExpenses),
			forecastNetProfits: addTotalAvg(this.forecastNetProfits),
		};

		// const format = TableItemFormatter.currency;
		const format = (n: number) => addComma(n, gdbx.numDecimal, true);

		const toItem = (o, n, i) => {
			o[monthTotalAvg[i]] = format(n);
			return o;
		};
		const salesRow = processed.sales.reduce(
			(o, num, i) => {
				const n = Number(num) || 0;
				const predicted = Number(processed.forecastSales[i]) || 0;
				const margin = n - predicted;
				const rate = n !== 0 ? (margin / n) * 100 : predicted;
				const positive = margin >= 0;
				o[monthTotalAvg[i]] = `${format(n)}(${positive ? '+' : ''}${rate.toFixed(
					1,
				)}%)`;
				o._cellVariants[monthTotalAvg[i]] = positive
					? 'forecast-cell-success'
					: 'forecast-cell-danger';
				return o;
			},
			{
				key: 'Actual Sales',
				_cellVariants: {},
			},
		);
		const forecastSales = processed.forecastSales.reduce(toItem, {
			key: 'Predicted Sales',
		});
		// const avgGrossProfits = processed.grossProfits.reduce(
		// 	(o, num, i) => {
		// 		const n = Number(num);
		// 		o.total += n;
		// 		o.avg[i] = o.total / (i + 1);
		// 		return o;
		// 	},
		// 	{
		// 		avg: [] as number[],
		// 		total: 0,
		// 	},
		// );
		const grossProfitsRow = processed.grossProfits.reduce(
			(o, num, i) => {
				const n = Number(num) || 0;
				const sale = Number(processed.sales[i]) || 0;
				const rate = sale !== 0 ? (n / sale) * 100 : 0;
				o[monthTotalAvg[i]] = `${format(n)}(${rate.toFixed(1)}%)`;
				const predicted = Number(processed.forecastGrossProfits[i]) || 0;
				const margin = n - predicted;
				const positive = margin >= 0;
				o._cellVariants[monthTotalAvg[i]] = positive
					? 'forecast-cell-success'
					: 'forecast-cell-danger';
				return o;
			},
			{
				key: 'Actual Gross Profit',
				_cellVariants: {},
			},
		);
		const forecastGrossProfits = processed.forecastGrossProfits.reduce(
			(o, num, i) => {
				const n = Number(num) || 0;
				const sale = Number(processed.forecastSales[i]) || 0;
				const rate = sale !== 0 ? (n / sale) * 100 : 0;
				o[monthTotalAvg[i]] = `${format(n)}(${rate.toFixed(1)}%)`;
				return o;
			},
			{
				key: 'Predicted Gross Profit',
				_cellVariants: {},
			},
		);
		const expensesRow = processed.expenses.reduce(
			(o, num, i) => {
				const n = Number(num) || 0;
				const predicted = Number(processed.forecastExpenses[i]) || 0;
				const margin = n - predicted;
				const rate = n !== 0 ? (margin / n) * 100 : predicted;
				const positive = margin >= 0;
				const good = margin <= 0;
				o[monthTotalAvg[i]] = `${format(n)}(${positive ? '+' : ''}${rate.toFixed(
					1,
				)}%)`;
				o._cellVariants[monthTotalAvg[i]] = good
					? 'forecast-cell-success'
					: 'forecast-cell-danger';
				return o;
			},
			{
				key: 'Actual Expenses',
				_cellVariants: {},
			},
		);
		const forecastExpenses = processed.forecastExpenses.reduce(toItem, {
			key: 'Predicted Expenses',
		});
		const netProfitsRow = processed.netProfits.reduce(
			(o, num, i) => {
				const n = Number(num) || 0;
				const predicted = Number(processed.forecastNetProfits[i]) || 0;
				const margin = n - predicted;
				const rate = n !== 0 ? (margin / n) * 100 : predicted;
				const positive = margin >= 0;
				o[monthTotalAvg[i]] = `${format(n)}(${positive ? '+' : ''}${rate.toFixed(
					1,
				)}%)`;
				o._cellVariants[monthTotalAvg[i]] = positive
					? 'forecast-cell-success'
					: 'forecast-cell-danger';
				return o;
			},
			{
				key: 'Actual Net Profit',
				_cellVariants: {},
			},
		);
		const forecastNetProfits = processed.forecastNetProfits.reduce(toItem, {
			key: 'Predicted Net Profit',
		});
		function addTotalVariance(o: any) {
			o._cellVariants.total += ' table-forecast-total';
		}
		addTotalVariance(salesRow);
		addTotalVariance(grossProfitsRow);
		addTotalVariance(expensesRow);
		addTotalVariance(netProfitsRow);

		const result: any[] = [
			forecastSales,
			salesRow,
			forecastGrossProfits,
			grossProfitsRow,
			forecastExpenses,
			expensesRow,
			forecastNetProfits,
			netProfitsRow,
		];
		return result;
	}

	public rowClass(
		item: any,
		type: 'row' | 'row-details' | 'row-top' | 'row-bottom' | 'table-busy',
	) {
		// if (!item || type !== 'row') return;
		// if (item.key === 'Actual Sales') return 'text-success';
	}
}
