
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { newForecast } from '@/components/forecast/newForecast';
import {
	ForecastDoc,
	SaveForecastDocSubmission,
	RemoveForecastDocSubmission,
} from '@/store/models.def';
import ForecastEditor from '@/components/forecast/ForecastEditor.vue';
import AllForecast from '../components/forecast/AllForecast.vue';
import ForeCast from '../components/forecast/Forecast.vue';
import accountx from '../store/modules/accountx';
import LoadingForecast from '../components/forecast/LoadingForecast.vue';
import PageError from '../components/containers/PageError.vue';
import progressToastx from '../store/modules/progressToastx';
@Component({
	components: {
		ForecastEditor,
		AllForecast,
		LoadingForecast,
		PageError,
	},
})
export default class Forecast extends Vue {
	public newDoc: ForecastDoc | null = null;

	public get componentName() {
		return !this.currentDocId
			? 'AllForecast'
			: this.currentDoc
			? 'ForecastEditor'
			: 'LoadingForecast';
	}
	public get currentDocId() {
		return this.$route.params.docId;
	}
	public get forecastUnauthorized() {
		return accountx.forecastUnauthorized;
	}
	public get forecastNotFound() {
		if (!this.currentDocId) {
			return false;
		}
		if (this.newDoc && this.currentDocId === this.newDoc.docId) {
			return false;
		}
		return Boolean(
			this.allForecasts &&
				!this.allForecasts.find((fc) => fc.docId === this.currentDocId),
		);
	}
	public get allForecasts() {
		return accountx.allForecasts;
	}

	public get currentDoc() {
		const existingDoc =
			this.allForecasts &&
			this.allForecasts.find((doc) => doc.docId === this.currentDocId);
		if (existingDoc) {
			return existingDoc;
		}
		if (this.newDoc && this.currentDocId === this.newDoc.docId) {
			return this.newDoc;
		}
		return null;
	}

	public async onDelete(docId: string) {
		const toDelete =
			this.allForecasts && this.allForecasts.find((doc) => doc.docId === docId);
		if (!toDelete) {
			return;
		}
		const value = await this.$bvModal.msgBoxConfirm('Are you sure?', {
			size: 'sm',
			cancelVariant: 'link-gray',
			okVariant: 'danger',
			okTitle: 'Delete',
		});
		if (value) {
			const submission: RemoveForecastDocSubmission = {
				accountId: accountx.currentAccountId!,
				docId,
			};
			const toastId = await progressToastx.add({
				title: `${toDelete.title}`,
				state: `Removing Forecast Document...`,
				variant: 'info',
			});
			const result = await accountx.removeForecast(submission);
			if (result.success) {
				progressToastx.done({ id: toastId, state: `Removed!` });
			} else {
				progressToastx.error({ id: toastId, state: result.errorMessage });
			}
			// this.docs = this.docs.filter((doc) => doc.docId !== docId);
		}
	}

	public async beforeRouteUpdate(to, from, next) {
		if (await this.checkEditorUnsaved()) {
			next(false);
		} else {
			next();
		}
		// const docId = to.params.docId;
		// if (docId) {
		// 	const document = this.docs.find((doc) => doc.docId === docId);
		// 	if (!document) {
		// 		next(false);
		// 	} else {
		// 		next();
		// 	}
		// } else {
		// 	next();
		// }
	}
	public async beforeRouteLeave(to, from, next) {
		if (await this.checkEditorUnsaved()) {
			next(false);
		} else {
			next();
		}
	}
	public async checkEditorUnsaved() {
		if (this.componentName === 'ForecastEditor') {
			const editor = this.$refs.page as ForecastEditor;
			return await editor.stopPageLeave();
		}
		return false;
	}

	public async onCreate() {
		if (!accountx.myPermissions || !accountx.myPermissions.forecastWrite) {
			const value = await this.$bvModal.msgBoxOk(
				'You do not have permission to create a forecast.',
				{
					size: 'sm',
					cancelVariant: 'link-gray',
					okVariant: 'danger',
					okTitle: 'Ok',
				},
			);
		} else if (this.allForecasts && this.allForecasts.length >= 10) {
			const value = await this.$bvModal.msgBoxOk(
				'Exceed limit. Remove unwanted forecast before create a new one.',
				{
					size: 'sm',
					cancelVariant: 'link-gray',
					okVariant: 'danger',
					okTitle: 'Ok',
				},
			);
		} else {
			this.newDoc = newForecast();
			// this.docs.unshift(newDoc);
			this.$router.push({
				name: 'ForecastEdit',
				params: { docId: this.newDoc.docId },
			});
		}
	}
	public async onSave(doc: ForecastDoc) {
		if (this.newDoc && this.newDoc.docId === doc.docId) {
			this.newDoc = doc;
		}

		const submission: SaveForecastDocSubmission = {
			accountId: accountx.currentAccountId!,
			forecast: doc,
		};
		const toastId = await progressToastx.add({
			title: `${doc.title}`,
			state: `Saving Forecast Document...`,
			variant: 'info',
		});
		const result = await accountx.saveForecast(submission);
		if (result.success) {
			progressToastx.done({ id: toastId, state: `Saved!` });
		} else {
			progressToastx.error({ id: toastId, state: result.errorMessage });
		}
	}
}
