
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ForecastCard from './ForecastCard.vue';
import { ForecastDoc } from '@/store/models.def';
import AddNewForecastCard from './AddNewForecastCard.vue';
@Component({
	components: {
		AddNewForecastCard,
		ForecastCard,
	},
})
export default class AllForecast extends Vue {
	@Prop({ default: () => [] }) public docs!: ForecastDoc[];
	@Prop({ default: '' }) public lastVisitedId!: string;

	public get numDocs() {
		return this.docs.length;
	}
}
